/* eslint-disable no-else-return */
/* eslint-disable prefer-template */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, {
    useImperativeHandle, useMemo, useRef, useState, useEffect
} from 'react';
import styles from './index.module.css';
import './index.css';

const defaultPoster = 'data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%2F%3E';

let seed = 0;

const Video = React.memo(React.forwardRef(({
    src,
    onPlay,
    onPause,
    onEnded,
    children,
    controls = true,
    poster = defaultPoster,
    ...props
}, ref) => {
    const id = useMemo(() => 'video_' + ++seed + '_' + Math.random().toString(36).slice(2), []);

    const [playing, setPlaying] = useState(false);

    const videoRef = useRef();

    const videoProxy = useMemo(() => ({
        get element() {
            return videoRef.current;
        },
        play: () => {
            return videoRef?.current?.play();
        },
        pause: () => videoRef.current.pause(),
        get paused() {
            return videoRef.current.paused;
        },
    }), []);

    useImperativeHandle(ref, () => videoProxy);

    const handlePlay = () => {
        setPlaying(true);
        onPlay && onPlay();
    };

    const handlePause = () => {
        setPlaying(false);
        onPause && onPause();
    };

    const handleEnded = () => {
        setPlaying(false);
        onEnded && onEnded();
    };

    const handleClick = () => {
        if (videoProxy.paused) {
            videoProxy.play();
        } else {
            videoProxy.pause();
        }
    };

    useEffect(() => {
        setTimeout(() => {
            if (videoRef?.current) {
                videoRef?.current?.play();
            }
        }, 500)
    }, [])
    

    console.log('[Video111]', videoRef);

    return (
        <div className='wrapper'>
        <svg width="9000" height="16000" className='svg'/>
        <div className='inner'>
            <video
                className={styles.video}
                style={{ width: '100%', height: '100%' }}
                id={id}
                ref={videoRef}
                controls={controls}
                muted={false}
                // onClick={handleClick} // 可以覆盖
                {...props}
                src={src}
                poster={poster}
                loop
                playsInline
                autoplay
                onPlay={handlePlay}
                onPause={handlePause}
                onEnded={handleEnded} />
                {/* {
                    !playing ? <img 
                    onClick={handleClick}
                    className={styles.play}
                    src="https://s3-hk-music-video.s3.ap-east-1.amazonaws.com/pics/play.png" alt="" /> : <div className={styles.stop}  onClick={handleClick} />
                } */}
                
            {(typeof children === 'function' ? children(playing) : children) || null}
        </div>
        </div>
    );
}));

export default Video;
