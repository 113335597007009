import { useState, useCallback, useEffect } from 'react';
import { useLocation } from "react-router-dom";
// import { CheckCircleOutline } from 'antd-mobile-icons';
import { debounce } from 'lodash';
import * as dayjs from 'dayjs'
import { Image, Ellipsis, Empty, InfiniteScroll } from 'antd-mobile';
import SearchInput from '../search-input';
import styles from './index.module.css';

// 
const demoSrc = 'https://s3-hk-music-video.s3.ap-east-1.amazonaws.com/pics/e728e670f14ef698929f9de95ac81951.webp';
// const demoSrc = 'https://s3-hk-music-video.s3.ap-east-1.amazonaws.com/pics/tt.png';
const SongItem = (props) => {
	const { setSelectId, active, name, playTime, artists } = props;
	const artistsNames = artists.map(i => i.name).join(' ')
	return (
		<div className={styles.song} onClick={() => {setSelectId(props.id)}}>
			<div className={active ? styles.leftActive : styles.left}>
                <Image src={demoSrc} width={50} height={50} fit='fill' style={{ borderRadius: 8 }} alt='' />
            </div>
			<div className={styles.right}>
                <div className={active ? styles.desActive : styles.des}>
                    <Ellipsis direction='end' content={name} />
                </div>
                <div className={active ? styles.timeActive : styles.time}>
					<Ellipsis direction='end' content={ artistsNames + '  |  ' + dayjs(playTime).format('mm:ss')} />
                </div>
            </div>
		</div>
	)
}

let cursor = 0;

const SelectSong = (props) => {
	const { selectId, setSelectId, songs } = props;
	const [result, setResult] = useState([]);
	const [list, setList] = useState([]);
	const [hasMore, setHasMore] = useState(true);
	const location = useLocation();

	const getSearchResult = useCallback(debounce((queryString) => {
		setList([]);
		const queryStringArr = queryString.split("");
		const str = "(.*?)";
		const filterMsg = [];
		const regStr = str + queryStringArr.join(str) + str;
		const reg = RegExp(regStr, "i"); // 以mh为例生成的正则表达式为/(.*?)m(.*?)h(.*?)/i
		songs && songs.forEach(item => {
			if (reg.test(item.name)) {
				filterMsg.push(item);
			}
		});
		setResult(filterMsg);
    }, 500), [songs]);

	// const getSearchResult = useCallback(queryString => {
	// 	setList([]);
	// 	const queryStringArr = queryString.split("");
	// 	const str = "(.*?)";
	// 	const filterMsg = [];
	// 	const regStr = str + queryStringArr.join(str) + str;
	// 	const reg = RegExp(regStr, "i"); // 以mh为例生成的正则表达式为/(.*?)m(.*?)h(.*?)/i
	// 	songs?.forEach(item => {
	// 		if (reg.test(item.name)) {
	// 			filterMsg.push(item);
	// 		}
	// 	});
	// 	setResult(filterMsg);
    // }, [songs]);

	const [keyword, setKeyword] = useState('');
	
	const onKeyPressed = useCallback((e) => {
		if (e.key === 'Enter') {
			// 按回车键时，offset置0，重新搜索
			// offsetRef.current = 0;
			// searchAll(keyword || placeholder);
		}
	}, []);

	const onKeyWordChanged = (e) => {
		setKeyword(e.target.value);
		getSearchResult(e.target.value);
		cursor = 0;
	};

	const clearWord = () => {
		setKeyword('');
		getSearchResult('');
		// setList([]);
		cursor = 0;
	};

	useEffect(() => {
		getSearchResult('');
	}, [getSearchResult]);

	const loadMore = useCallback(() => {
		if (!result?.length) return;
		console.log('pre =====', cursor);
		const append = result.slice(cursor, cursor+10);
		cursor += 10;
		console.log('cursor =====', cursor);
		// console.log(append);
		setList(val => [...val, ...append])
		setHasMore(cursor < result.length)
	}, [result]);

	useEffect(() => {
		loadMore();
	}, [loadMore]);

	useEffect(() => {
		cursor = 0;
	}, [location])
	

	return (
        <div className={styles.wrapper}>
			<div className={styles.title}>曲库</div>
			<SearchInput
				keyWord={keyword}
				placeholder={'搜索更多'}
				onKeyPressed={onKeyPressed}
				onKeyWordChanged={onKeyWordChanged}
				clearWord={clearWord}
			/>
			<div className={styles.list}>
				<div className={styles.result}>
					{
						list.map((item) => {
							return (
								<SongItem {...item} setSelectId={setSelectId} active={selectId === item.id} key={item.id} />
							)
						})
					}
					{
						!list?.length && <Empty description='暂无数据' />
					}
				</div>
				{
					result?.length ? <InfiniteScroll loadMore={loadMore} hasMore={hasMore} /> : null
				}
			</div>
        </div>
	);
};

export default SelectSong