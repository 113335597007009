const fixUrl = (url) => {
  // if(window.location.host === 'www.easymusicvideo.com') {
  //   return 'https://easymusicvideo.com' + url
  // }
  return url
}

const fetchWrapper = (url, data, config) => {
    return fetch(`${fixUrl(url)}`, {
        data,
        // "Content-Type": "multipart/form-data",
        ...config
    }).then((res) => res.json());
}

export const postData = (url, data) => {
  // Default options are marked with *
  return fetch(fixUrl(url), {
    body: new URLSearchParams(data), // must match 'Content-Type' header
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, same-origin, *omit
    headers: {
      'content-type': 'application/x-www-form-urlencoded'
    },
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, cors, *same-origin
    redirect: 'follow', // manual, *follow, error
    referrer: 'no-referrer' // *client, no-referrer,
  })
  .then(response => response.json()) // parses response to JSON
}


export default fetchWrapper;
