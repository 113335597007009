// import { useReducer } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import { RedoOutline, CheckOutline } from 'antd-mobile-icons';
import { Toast, SpinLoading } from 'antd-mobile';
import Nav from '../../components/nav';
import { FULL_WIDTH, FULL_HEIGHT } from '../../utils/consts';
import Video from '../../components/video';
import { postData } from '../../utils/fetch';
import { log } from '../../utils/api';
import styles from './index.module.css';

const PIC_WIDTH = FULL_WIDTH - 40;
const PIC_HEIGHT = FULL_HEIGHT - 180;

const generateTime = (max = 30, min = 15) => {
    return (Math.random() * (max - min) + min) * 100
}

function Process() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const songId = searchParams.get('songId');
    const [videoInfo, setVideoInfo] = useState({});
    const [loading, setLoading] = useState(true);

    const goResult = () => {
        navigate(`/result?songId=${encodeURIComponent(songId)}&videoUrl=${encodeURIComponent(videoInfo.url)}&resKey=${videoInfo.s3Key}&ossKey=${encodeURIComponent(videoInfo.ossKey || '')}&ossUrl=${encodeURIComponent(videoInfo.ossUrl || '')}`);
    }

    const getVideonInfo = useCallback(() => {
        if (!songId) return;
        setLoading(true);
        postData(`/api/rep/video/song/info/url/get`, {
            songId
        }).then(res => {
            if (res.code !== 200) {
                Toast.show({content: '视频生成失败，请重试'});
                setTimeout(() => {
                    setLoading(false);
                }, generateTime())
            } else {
                const { data } = res;
                setVideoInfo({
                    s3Key: data[0]?.s3Key,
                    url: data[0]?.url,
                    ossKey: data[0]?.ossKey,
                    ossUrl: data[0]?.ossUrl
                });
                setTimeout(() => {
                    setLoading(false);
                }, generateTime())
            }
        }).catch(err => {
            Toast.show({content: '视频生成失败，请重试'});
            setTimeout(() => {
                setLoading(false);
            }, generateTime())
        })
    }, [songId])

    useEffect(() => {
        getVideonInfo();
    }, [getVideonInfo]);

    useEffect(() => {
        log(encodeURIComponent(window.location.href));
    }, []);


    return (
        <div className={styles.container}>
            <Nav />
            <div className={styles.videoWrapper} style={{
                width: PIC_WIDTH,
                height: PIC_HEIGHT,
                borderRadius: '16px',
            }}>
                {loading ? <SpinLoading style={{ '--size': '64px' }} /> : <Video src={videoInfo.ossUrl || videoInfo.url}/>}
            </div>
            <div className={styles.items}>
                <div className={styles.item} onClick={getVideonInfo}>
                    <RedoOutline fontSize={36} color='#fff' />
                    <div style={{ width: '100px' }} className={styles.txt}>重新生成</div>
                </div>

                <div style={{ width: '100px' }}></div>
                <div className={styles.item} onClick={goResult}>
                    <CheckOutline fontSize={36} color='#fff' />
                    <div style={{ width: '100px' }} className={styles.txt}>确认</div>
                </div>
            </div>
        </div>
    );
}

export default Process;
