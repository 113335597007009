/* eslint-disable jsx-a11y/alt-text */
// import { useNavigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { SendOutline } from 'antd-mobile-icons';
import { NavBar, Toast } from 'antd-mobile';
import Clipboard from 'react-clipboard.js';
import styles from "./index.module.css";


const Nav = () => {
    // const navigate = useNavigate();
    // const { onBack } = props;

    const navigate = useNavigate();

    const back = () => {
        navigate('/')
    }

    const handleCopy = () => {
        Toast.show('已复制链接到剪贴板，快去分享给小伙伴试试');
    }

    const BackIcon = (
        <div className={styles.home} onClick={back}>
            <svg t="1723117963871" className={styles.svg} viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8459" width="200" height="200"><path d="M946.5 505L560.1 118.8l-25.9-25.9c-12.3-12.2-32.1-12.2-44.4 0L77.5 505c-12.3 12.3-18.9 28.6-18.8 46 0.4 35.2 29.7 63.3 64.9 63.3h42.5V940h691.8V614.3h43.4c17.1 0 33.2-6.7 45.3-18.8 12.1-12.1 18.7-28.2 18.7-45.3 0-17-6.7-33.1-18.8-45.2zM568 868H456V664h112v204z m217.9-325.7V868H632V640c0-22.1-17.9-40-40-40H432c-22.1 0-40 17.9-40 40v228H238.1V542.3h-96l370-369.7 23.1 23.1L882 542.3h-96.1z" p-id="8460" fill="#ffffff"></path></svg>
        </div>
    );

    const shareIcon = (
        <div onClick={handleCopy}>
            <Clipboard data-clipboard-text={`${window.location.origin}/koc/task?ua=${encodeURIComponent(navigator.userAgent)}`} style={{}}>
                <SendOutline fontSize={24} color='#fff'/>
            </Clipboard>
        </div>
    )
 
    return (
        <NavBar onBack={back} backIcon={BackIcon} style={{paddingLeft: '16px', paddingRight: '16px'}} right={shareIcon}></NavBar>
    );
}
  
export default Nav;
  