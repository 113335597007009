// import { useReducer } from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Button, Popup, SafeArea, NoticeBar } from 'antd-mobile';
// import { FULL_WIDTH, FULL_HEIGHT } from '../../utils/consts';
import BrowserOpen from '../../components/browser-open';
import SelectSong from './components/select-song';
import styles from './index.module.css';
import fetch from '../../utils/fetch';
import { log } from '../../utils/api';
import { postData } from '../../utils/fetch';
// import { MockSongs } from './mock';

// const MAIN_PIC = 'https://s3-hk-music-video.s3.ap-east-1.amazonaws.com/pics/jorik-kleen-Nio0W6TWkaU-unsplash+(1).jpg';

// const PIC_WIDTH = FULL_WIDTH - 60;
// const PIC_HEIGHT = PIC_WIDTH * 16 / 9;


function Home() {
    const navigate = useNavigate();

    const [songs, setSongs] = useState(false);
    const [visible, setVisible] = useState(false);
    const [selectId, setSelectId] = useState(false);

    const showPopup = () => {
        setVisible(true);
    }

    useEffect(() => {
        fetch('/api/rep/video/song/info/get').then(res => {
            const { data } = res;
            if(data?.length) {
                setSongs(data);
                // setSongs(MockSongs)
            }
            console.log(res);
        });
        log(encodeURIComponent(window.location.href));
    }, [])

    const go2Process = () => {
        navigate(`/process?songId=${encodeURIComponent(selectId)}`);
    }

    return (
        <div className={styles.container}>
            <div
                className={styles.cover}
            >
                <NoticeBar content='推荐使用系统自带浏览器或者夸克浏览器' color='info' />
                <a href={`https://s3-hk-music-video.s3.ap-east-1.amazonaws.com/obj/fi3Cvgtdw43CtkY/345672336734/28d6/33c4/4e33/8dc1d873156a8b2de782db8fb8f5a9c6.mp4?response-content-disposition=attachment%3B%20filename%3D8dc1d873156a8b2de782db8fb8f5a9c6.mp4&response-content-type=application%2Foctet-stream&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20240903T015535Z&X-Amz-SignedHeaders=host&X-Amz-Expires=86400&X-Amz-Credential=AKIASQONKMAZQCN6TE2P%2F20240903%2Fap-east-1%2Fs3%2Faws4_request&X-Amz-Signature=bdeb0d7e1784df8ac1fbbe3b4f5ac24d2d2a87f107b4558160ccbe966c991ae1`} style={{ display: 'block', opacity: 0, width: '50px', height: '50px'}} download="1111.mp4">111</a>
                <div className={styles.wrapper}>
                    <div className={styles.title}>音乐视频，一键生成！</div>
                    <div className={styles.inner}>
                        <div className={styles.tips}>
                            <div className={styles.tip}>无需专业技能，快速上手制作</div>
                            <div className={styles.tip}>海量热门音乐，激发你的创作灵感</div>
                            <div className={styles.tip}>与全球音乐爱好者分享和交流</div>
                        </div>
                        <div className={styles.btn}>
                            <Button block size='large' color='warning' onClick={showPopup} className={styles.select}>
                                选择歌曲
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <Popup
                visible={visible}
                onMaskClick={() => {
                    setVisible(false)
                }}
                onClose={() => {
                    setVisible(false)
                }}
                showCloseButton
                bodyStyle={{ height: '58vh', borderTopLeftRadius: '20px', borderTopRightRadius: '20px', background: '#333' }}
            >
                <SelectSong selectId={selectId} setSelectId={setSelectId} songs={songs} />
                <div className={styles.confirmWrapper}>
                    <Button size='large' block onClick={go2Process} color='warning' className={styles.confirm}>
                        确定
                    </Button>
					<SafeArea position='bottom' />
				</div>
            </Popup>
            <BrowserOpen />
        </div>
    );
}

export default Home;
