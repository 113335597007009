import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";
import { DownlandOutline } from "antd-mobile-icons";
import { Toast, SpinLoading } from 'antd-mobile';
// import { saveAs } from 'file-saver';

import { postData } from '../../utils/fetch';
import { FULL_WIDTH, FULL_HEIGHT } from '../../utils/consts';
import Nav from '../../components/nav';
import Video from '../../components/video';
import { log } from '../../utils/api';

import styles from './index.module.css';


const PIC_WIDTH = FULL_WIDTH - 40;
// const PIC_HEIGHT = PIC_WIDTH * 16 / 9;
const PIC_HEIGHT = FULL_HEIGHT - 150;


const addRecord = (songId, key) => {
    fetch(`/api/rep/video/song/down/record/add?songId=${songId}&key=${key}`)
}

const checkValid = (str) => {
    if (str === 'null' || str === 'undefined') {
        return false
    }
    return str
}


function Home() {
    const [searchParams] = useSearchParams();
    const songId = searchParams.get('songId');
    // 优先使用 oss 数据
    const videoUrl = checkValid(searchParams.get('ossUrl')) || searchParams.get('videoUrl');
    const resKey = checkValid(searchParams.get('ossKey')) || searchParams.get('resKey');
    const [videoInfo, setVideoInfo] = useState({});
    const [loading, setLoading] = useState(false);
    const [tip, setTip] = useState(false);


    const getVideonInfo = useCallback(() => {
        if (!songId) return;
        console.log(songId);
        postData(`/api/rep/video/song/info/url/get`, {
            songId
        }).then(res => {
            if (res.code !== 200) {
                Toast.show({ content: '视频生成失败，请重试' });
            } else {
                const { data } = res;
                setVideoInfo({
                    s3Key: data[0]?.s3Key,
                    url: data[0]?.url
                })
            }
        }).catch(err => {
            Toast.show({ content: '视频生成失败，请重试' });
        })
    }, [songId])

    useEffect(() => {
        getVideonInfo();
    }, [getVideonInfo])

    /*
        表单方案不行
        a 标签不行，会直接打开新页面
        iframe 不行
    */
    const downVideo = (url, name = 'musicvideo') => {
        addRecord(songId, resKey);
        setLoading(true);
        var xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.responseType = 'arraybuffer';    // 返回类型blob
        xhr.onload = function () {
            if (xhr.readyState === 4 && xhr.status === 200) {
                let blob = this.response;
                // 转换一个blob链接
                let u = window.URL.createObjectURL(new Blob([blob], { type: 'video/mp4' })) // 视频的type是video/mp4，图片是image/jpeg
                let a = document.createElement('a');
                a.download = name; // 设置下载的文件名
                a.href = u;
                a.style.display = 'none';
                console.log('ready');
                document.body.appendChild(a)
                a.click();
                a.remove();
                // if (navigator.userAgent.indexOf('QQBrowser') > -1 || navigator.userAgent.indexOf('UCBrowser') > -1 || navigator.userAgent.indexOf('baidu') > -1) {
                //     setTip('如遇视频保存失败，可以尝试使用Safari、夸克浏览器');
                //     Toast.show({ content: '如遇视频保存失败，可以尝试使用Safari、夸克浏览器', icon: 'fail' });
                // }
            }
            setLoading(false);
        };
        xhr.onerror = () => {
            setLoading(false);
        }
        xhr.send();

    }

    const downloatVideo = () => {
        downVideo(videoUrl, resKey)
    }

    useEffect(() => {
        log(encodeURIComponent(window.location.href));
    }, []);


    const getDownloadBtn = () => {
        if (/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)) {
            return loading ?
            <SpinLoading style={{ '--size': '26px' }} /> :
            <div className={styles.item} onClick={downloatVideo}>
                <DownlandOutline fontSize={36} color='#fff' />
                <div style={{ width: '100px' }} className={styles.txt}>保存</div>
                {tip ? <div className={styles.txt}>{tip}</div> : null}
            </div>
        }

        return (
            <a href={`${videoUrl}`} style={{ display: 'block', width: '50px', height: '50px' }} download={`${resKey}`}>
                <div className={styles.item} onClick={() => {addRecord(songId, resKey);}}>
                    <DownlandOutline fontSize={36} color='#fff' />
                    <div style={{ width: '100px' }} className={styles.txt}>保存</div>
                    {tip ? <div className={styles.txt}>{tip}</div> : null}
                </div>
            </a>
        );
        
    }

    return (
        <div className={styles.container}>
            <Nav />
            <div className={styles.videoWrapper} style={{
                width: PIC_WIDTH,
                height: PIC_HEIGHT,
                borderRadius: '16px',
                overflow: 'hidden'
            }}>
                <Video
                    src={videoUrl || videoInfo.url}
                />

            </div>
            <div className={styles.items}>
                {getDownloadBtn()}
            </div>
        </div>
    );
}

export default Home;
