import { Modal } from 'antd-mobile';
import { useEffect, useState } from 'react';
const weChatPattern = /MicroMessenger/gi;
  const kolPattern = /KolApp/gi;
  const userAgent = window.navigator.userAgent;

const BrowserOpen = () => {
    const [visible, setVisible] = useState(false);
    useEffect(() => {
      if(userAgent.match(weChatPattern) || userAgent.match(kolPattern)) {
        setVisible(true);
      }
    }, [])
    return (
        <Modal
        visible={visible}
        content={<div style={{fontSize: '20px', textAlign: 'center'}}>请在浏览器中打开</div>}
        closeOnAction
        onClose={() => {
          if(userAgent.match(kolPattern)) {
            setVisible(false);
          }
        }}
        actions={[
          // {
          //   key: 'confirm',
          //   text: '我知道了',
          // },
        ]}
      />
    )
}

export default BrowserOpen;