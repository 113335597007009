/* eslint-disable jsx-a11y/alt-text */
import { useRef, useEffect, useState } from 'react';
// import { debounce } from 'lodash';
import styles from './index.module.css';


const SEARCH = 'https://p5.music.126.net/obj/wo3DlcOGw6DClTvDisK1/30930781682/99b1/90d7/63ac/4ab6a2e6c424a001f75a580219a1d845.png';
const CLOSE = 'https://p5.music.126.net/obj/wo3DlcOGw6DClTvDisK1/31270884043/ce4f/7914/33d4/05cc4119fc2d802fe390de196d6095ec.png';


const SearchInput = (props) => {
    const { keyWord, placeholder, onKeyWordChanged, onKeyPressed, clearWord } = props;
    const inputRef = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            if (inputRef.current) {
                // @ts-ignore
                inputRef.current.focus();
            }
        }, 0);
    }, []);
    return (
        <div className={styles.searchInput}>
            {/* <img className={styles.search} src={SEARCH} /> */}
            <input type="text"
                className={styles.input}
                placeholder={placeholder}
                value={keyWord}
                onChange={onKeyWordChanged}
                onKeyPress={onKeyPressed}
                ref={inputRef}
            />
            {
                keyWord ? <img className={styles.clear} src={CLOSE} onClick={clearWord} /> : null
            }
        </div>
    );
}
export default SearchInput;
