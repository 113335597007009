import React from 'react';
import ReactDOM from 'react-dom/client';
import { Routes, Route, BrowserRouter } from "react-router-dom";
// import Context from './context';
import Home from './pages/home';
import Process from './pages/process';
import Result from './pages/result';
import {  PREFFIX } from "./utils/consts";

import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

const Root = () => {
    return (
        <React.StrictMode>
            <BrowserRouter basename={PREFFIX}>
                <Routes>
                    <Route path='*' element={<Home />} />
                    <Route index element={<Home />} />
                    <Route path="process" element={<Process />} />
                    <Route path="result" element={<Result />} />
                </Routes>
            </BrowserRouter>
        </React.StrictMode>
    )
}

root.render(
    <Root />
);


